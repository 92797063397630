<template>
  <div class="dashboard">
    <modal :show.sync="negativacao.add" :title="'Informar negativação de '+negativacao.razaoSocial">
      <template slot="body">
        <va-date-picker
          :config="{mode: 'single', dateFormat: 'd/m/Y', allowInput: false}"
          v-model="negativacao.data"
          label="Data Negativação"
        />

        <va-input
          v-model="negativacao.valor"
          label="Valor Negativado"
        />

        <va-checkbox
          v-model="negativacao.avalistas"
          label="Os avalistas também foram negativados"
          class="mb-3"
        />

        <va-date-picker v-show="negativacao.avalistas"
          :config="{mode: 'single', dateFormat: 'd/m/Y', allowInput: false}"
          v-model="negativacao.dataAvalistas"
          label="Data Negativação dos Avalistas"
        />

      </template>

      <template slot="footer">
        <va-button
          outline
          color="success"
          v-on:click="salvarNegativacao"
        >
          Gravar
        </va-button>

      </template>
    </modal>

    <modal :show.sync="negativacao.addAvalistas" :title="'Informar negativação dos avalistas de '+negativacao.razaoSocial">
      <template slot="body">
        <va-date-picker
          :config="{mode: 'single', dateFormat: 'd/m/Y', allowInput: false}"
          v-model="negativacao.dataAvalistas"
          label="Data Negativação dos Avalistas"
        />
      </template>

      <template slot="footer">
        <va-button
          outline
          color="success"
          v-on:click="salvarNegativacaoAvalistas"
        >
          Gravar
        </va-button>

      </template>
    </modal>

    <va-card :title="$t('negativacao.paraNegativarCNPJ.title') + ` - ${filteredData.length} operações encontradas`">
      <div class="row align--center mb-1">
        <div class="flex xs12 sm6">
          <va-input
            class="ma-0"
            :value="term"
            :placeholder="$t('tables.searchByName')"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>

        </div>

        <div class="flex xs12 md3 offset--md3">
          <va-select
            :options="perPageOptions"
            v-model="perPage"
            label="Itens por página"
          />
        </div>
      </div>
      <div class="row align--center mb-1">
        <div class="flex sm1 xs12">

          <va-radio-button
            v-model="filtro"
            option="todos"
            label="Todos"
          />
        </div>
        <div class="flex sm2">
          <va-radio-button
            v-model="filtro"
            option="faltaNegativarCNPJ"
            label="Faltam negativar CNPJ"
          />
        </div>
        <div class="flex sm3 xs12">
          <va-radio-button
            v-model="filtro"
            option="faltaNegativarAvalistas"
            label="Faltam negativar avalistas"
          />
        </div>
      </div>

      <va-data-table
        :per-page="perPage"
        :fields="mode ? detailedFields : fields"
        :data="filteredData"
        :loading="loading"
        hoverable
      >
        <template slot="razaoSocial" slot-scope="props">
          <a :href="`https://zxvf.tutudigital.com.br/tomadores/${props.rowData.tomadorId}/dossie1`" target="_blank">
            {{props.rowData.razaoSocial}}
          </a>
        </template>
        <template slot="negativado" slot-scope="props">
          <a v-on:click="addNegativacao(props.rowData)" title="Negativar" alt="Negativar" v-if="props.rowData.negativado === 'Não'" href="javascript://">
            <i class="va-icon vuestic-iconset vuestic-iconset-auth"></i>
          </a>
          <va-icon name="entypo entypo-check" style="color: #1b9a7c;" v-if="props.rowData.negativado === 'Sim'" />
        </template>

        <template slot="avalistasNegativados" slot-scope="props">
          <a v-on:click="addNegativacaoAvalistas(props.rowData)" title="Negativar" alt="Negativar" v-if="props.rowData.avalistasNegativados === 'Não' && props.rowData.negativado === 'Sim'" href="javascript://">
            <i class="va-icon vuestic-iconset vuestic-iconset-auth"></i>
          </a>
          <va-icon name="entypo entypo-check" style="color: #1b9a7c;" v-if="props.rowData.avalistasNegativados === 'Sim'" />
          <va-icon name="entypo entypo-cancel-circled" style="color: #1b9a7c;" v-if="props.rowData.avalistasNegativados === 'Não' && props.rowData.negativado === 'Não'" />

        </template>

      </va-data-table>
    </va-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import negativacoesService from '../../services/Cobranca/NegativacoesService'
import Modal from '../../components/modal/Modal'

export default {
  components: { Modal },
  data () {
    return {
      filtro: 'todos',
      operacoes: [],
      perPage: 15,
      perPageOptions: ['15', '25', '35', '50', '75', '100', '200', '300', '400', '500'],
      loading: false,
      term: null,
      mode: 0,

      negativacao: {
        add: false,
        addAvalistas: false,
        emprestimoId: 0,
        razaoSocial: '',
        data: '',
        valor: 0,
        dataAvalistas: null,
        avalistas: false,
        acordoId: null,
      },
    }
  },
  watch: {
    'negativacao.avalistas': {
      handler: function (after, before) {
        if (!this.negativacao.avalistas) {
          this.negativacao.dataAvalistas = null
        } else {
          if (!this.negativacao.dataAvalistas) {
            this.negativacao.dataAvalistas = this.negativacao.data
          }
        }
      },
      deep: true,
    },
    'negativacao.data': {
      handler: function (after, before) {
        if (this.negativacao.avalistas && (!this.negativacao.dataAvalistas || before === this.negativacao.dataAvalistas)) {
          this.negativacao.dataAvalistas = this.negativacao.data
        }
      },
      deep: true,
    },
  },
  computed: {
    fields () {
      return [{
        name: 'id',
        title: 'EmprestimoId',
      }, {
        name: '__slot:razaoSocial',
        title: 'Razão Social',
      }, {
        name: 'acordoId',
        title: 'AcordoId',
      },
      {
        name: 'statusAcordo',
        title: 'Status Acordo',
      },
      {
        name: 'diasVencidoPrimeiraParcela',
        title: 'Dias Vencido',
      },
      {
        name: 'negativado',
        title: 'CNPJ Neg.',
      },
      {
        name: 'todosAvalistasNegativados',
        title: 'Todos Avalistas Neg.',
      },
      {
        name: 'algumAvalistaNegativado',
        title: 'Algum Avalista Neg.',
      },
      {
        name: 'enviadoParaJuridico',
        title: 'Enviado para JURÍDICO',
      },
      {
        name: 'numeroProcesso',
        title: 'Número Processo',
      }]
    },
    modeOptions () {
      return [{
        value: 0,
        label: this.$t('dashboard.table.brief'),
      }]
    },
    filteredData () {
      let ret = null
      if (!this.term || this.term.length < 1) {
        ret = this.operacoes
      } else {
        ret = this.operacoes.filter(item => {
          return item.razaoSocial.toLowerCase().startsWith(this.term.toLowerCase())
        })
      }

      if (this.filtro === 'faltaNegativarCNPJ') {
        ret = ret.filter(x => x.negativado === 'Não')
      } else if (this.filtro === 'faltaNegativarAvalistas') {
        ret = ret.filter(x => x.avalistasNegativados === 'Não')
      }

      return ret
    },
  },
  methods: {
    addNegativacao (item) {
      this.negativacao.emprestimoId = item.id
      this.negativacao.razaoSocial = item.razaoSocial
      this.negativacao.add = true
      this.negativacao.data = null
      this.negativacao.dataAvalistas = null
      this.negativacao.avalistas = false
      this.negativacao.acordoID = item.acordoId
    },
    addNegativacaoAvalistas (item) {
      this.negativacao.emprestimoId = item.id
      this.negativacao.razaoSocial = item.razaoSocial
      this.negativacao.addAvalistas = true
      this.negativacao.data = null
      this.negativacao.dataAvalistas = null
      this.negativacao.valor = null

      this.negativacao.acordoID = item.acordoId
    },
    async salvarNegativacao () {
      (await negativacoesService.informarNegativacao({
        acordoId: this.negativacao.acordoId,
        emprestimoId: this.negativacao.emprestimoId,
        valor: parseFloat(this.negativacao.valor.replace(',', '.')),
        data: this.negativacao.data,
        dataNegativacaoAvalistas: this.negativacao.dataAvalistas,
      }))

      this.operacoes = (await negativacoesService.paraNegativarCNPJ()).data.data
      this.negativacao.add = false
    },
    async salvarNegativacaoAvalistas () {
      (await negativacoesService.informarNegativacaoAvalistas({
        acordoId: this.negativacao.acordoId,
        emprestimoId: this.negativacao.emprestimoId,
        data: this.negativacao.dataAvalistas,
      }))

      this.operacoes = (await negativacoesService.paraNegativarCNPJ()).data.data
      this.negativacao.addAvalistas = false
    },
    getStatusColor (status) {
      if (status === 'paid') {
        return 'success'
      }

      if (status === 'processing') {
        return 'info'
      }

      return 'danger'
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
  async mounted () {
    this.operacoes = (await negativacoesService.paraNegativarCNPJ()).data.data
  },
}
</script>
